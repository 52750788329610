import { ButtonBase } from "src/components/Button/ButtonBase";
import styled from "styled-components";

import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { fontSize } from "src/theme";
import { Plus } from "src/svg/tripplanner/Plus";
import { font_weight } from "src/design-system/tokens/typography";
import { useIntl } from "react-intl";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { Fragment, useState } from "react";
import Close from "src/svg/Close.svg?react";
import Marker from "src/svg/BrandDestination.svg?react";
import { TripPlannerAutocomplete } from "../TripPlannerAutocomplete/TripPlannerAutocomplete";
import messages from "./PlaceInputList.messages";

type PlaceInputListProps = {
  places: string[];
  handleRemoveDestination: (index: number) => void;
  handleAddDestination: (autocompletePlace: AutocompletePlace) => void;
  handleEditDestination: (
    index: number,
    autocompletePlace: AutocompletePlace
  ) => void;
};
export function PlaceInputList({
  places,
  handleAddDestination,
  handleEditDestination,
  handleRemoveDestination,
}: PlaceInputListProps) {
  const intl = useIntl();

  return (
    <>
      <WrapperDiv>
        <List>
          {places.length <= 2 ? (
            <>
              <Input
                place={places[0] ?? intl.formatMessage(messages.startingFrom)}
                index={0}
                handleEditDestination={
                  places[0] ? handleEditDestination : undefined
                }
                handleAddDestination={handleAddDestination}
                handleRemoveDestination={
                  places[0] ? handleRemoveDestination : undefined
                }
                $isEmpty={!places[0]}
                places={places}
              />
              {places[1] ? <LinkDiv /> : <DottedLine />}

              <Input
                place={places[1] ?? intl.formatMessage(messages.goingTo)}
                index={1}
                handleEditDestination={
                  places[1] ? handleEditDestination : undefined
                }
                handleAddDestination={handleAddDestination}
                handleRemoveDestination={
                  places[1] ? handleRemoveDestination : undefined
                }
                $isEmpty={!places[1]}
                places={places}
              />
            </>
          ) : (
            <>
              {places.map((place, index) => {
                return (
                  <Fragment key={`${index + place}`}>
                    <Input
                      place={place}
                      places={places}
                      index={index}
                      handleEditDestination={handleEditDestination}
                      handleAddDestination={handleAddDestination}
                      handleRemoveDestination={handleRemoveDestination}
                    />
                    {places.length - 1 !== index ? <LinkDiv /> : null}
                  </Fragment>
                );
              })}
            </>
          )}
        </List>
        <DottedLine />
        <AddDestinationButton
          handleAddDestination={handleAddDestination}
          isDisabled={places.length < 2}
        />
      </WrapperDiv>
    </>
  );
}

function AddDestinationButton({
  handleAddDestination,
  isDisabled,
}: {
  handleAddDestination: (place: AutocompletePlace) => void;
  isDisabled: boolean;
}) {
  const intl = useIntl();
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false);

  return (
    <>
      {isAutocompleteActive && (
        <TripPlannerAutocomplete
          handleClose={() => setIsAutocompleteActive(false)}
          onSelectOption={(place) => {
            handleAddDestination(place);
            setIsAutocompleteActive(false);
          }}
        />
      )}
      <AddButton
        isDisabled={isDisabled}
        disabled={isDisabled}
        onClick={() => setIsAutocompleteActive(true)}
      >
        <AddSvg tint={isDisabled ? "grey3" : "pink"} />
        {intl.formatMessage(messages.addDestination)}
      </AddButton>
    </>
  );
}

function Input({
  index,
  handleEditDestination,
  handleAddDestination,
  handleRemoveDestination,
  place,
  places,
  $isEmpty,
}: {
  index: number;
  place: string;
  places: string[];
  handleEditDestination?: (index: number, place: AutocompletePlace) => void;
  handleAddDestination: (place: AutocompletePlace) => void;
  handleRemoveDestination?: (index: number) => void;
  $isEmpty?: boolean;
}) {
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false);
  const intl = useIntl();

  function getIcon(index: number, $isEmpty?: boolean) {
    if (index === 0 && places.length <= 2) {
      return <StarterDiv $isEmpty={$isEmpty} />;
    } else if (index === 1 && places.length <= 2) {
      return <MarkerSvg $isEmpty={$isEmpty} />;
    } else {
      return <DotDiv />;
    }
  }

  return (
    <>
      {isAutocompleteActive && (
        <TripPlannerAutocomplete
          // If we're 'editing' input 1 or 2 after clearing it, we're just adding
          onSelectOption={(place) => {
            handleEditDestination
              ? handleEditDestination(index, place)
              : handleAddDestination(place);
            setIsAutocompleteActive(false);
          }}
          handleClose={() => setIsAutocompleteActive(false)}
        />
      )}
      <Item>
        {getIcon(index, $isEmpty)}

        <InputButton
          $isEmpty={$isEmpty}
          onClick={() => setIsAutocompleteActive(true)}
        >
          <InputSpan>{place}</InputSpan>
        </InputButton>

        {handleRemoveDestination && (
          <RemoveButton
            onClick={() => handleRemoveDestination(index)}
            role="button"
            title={intl.formatMessage(messages.removePlace)}
          >
            <Close width={12} height={12} color={color.icon.secondary} />
          </RemoveButton>
        )}
      </Item>
    </>
  );
}

function DottedLine() {
  return (
    <DottedLineDiv>
      <DottedLineSvg width="2" height="40">
        <line
          x1="1"
          y1="0"
          x2="1"
          y2="44"
          stroke={color.icon.secondary}
          strokeDashoffset="1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="0, 6"
        />
      </DottedLineSvg>
    </DottedLineDiv>
  );
}

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const List = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;
const Item = styled.ul`
  display: flex;
  align-items: center;
  background-color: ${color.bg.surface.active};
  :first-child {
    border-radius: ${spacing.md} ${spacing.md} 0 0;
  }
  :last-child {
    border-radius: 0 0 ${spacing.md} ${spacing.md};
  }
`;

const DotDiv = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${color.icon.icon};
  margin-left: ${spacing.xl};
`;

const InputButton = styled(ButtonBase)<{ $isEmpty?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${spacing.xl};
  padding-right: 0;
  flex: 1;
  font-size: ${fontSize.h5};
  overflow: hidden;
  white-space: nowrap;
  color: ${({ $isEmpty }) =>
    $isEmpty ? color.icon.icon : color.text.primary.active};
`;

const InputSpan = styled.span`
  text-align: left;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(ButtonBase)`
  display: flex;
  padding: ${spacing.xl};
`;

const AddSvg = styled(Plus)`
  margin-left: 14px;
  margin-right: 14px;
  width: 14px;
  height: 14px;
  z-index: 1;
`;

const AddButton = styled(ButtonBase)<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  color: ${(props) =>
    props.isDisabled ? color.text.tertiary : color.bg.brand.fill};
  font-weight: ${font_weight.medium};
  padding: ${spacing.xl};
  padding-left: 0;
`;

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
  ::before {
    position: absolute;
    content: "";
    height: 44px;
    border-left: 1px solid ${color.icon.secondary};
  }
`;

const DottedLineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
`;
const DottedLineSvg = styled.svg`
  position: absolute;
`;

const StarterDiv = styled.div<{ $isEmpty?: boolean }>`
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  border: 6px solid
    ${({ $isEmpty }) => ($isEmpty ? color.bg.brand.fill : color.icon.icon)};
  border-radius: 50%;
  margin-left: 12.5px;
  z-index: 1;
`;

const MarkerSvg = styled(Marker)<{ $isEmpty?: boolean }>`
  margin-left: 11px;
  z-index: 1;
  path {
    fill: ${({ $isEmpty }) =>
      $isEmpty ? color.bg.brand.fill : color.icon.icon};
  }
`;
