import Cookies from "js-cookie";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import {
  ExperimentConfig,
  useExperimentConfig,
} from "src/experiment/useExperimentConfig";
import styled from "styled-components";
import { useIsValidSegment } from "src/domain/SegmentScreen/useEnsureValidSegment";
import { useFeature } from "src/feature/useFeature";
import { localeToLanguageCode } from "../../utils/conversions/languageCode";
import useSearch from "../../utils/hooks/useSearch";
import { isAdWordsAcquisition } from "../../utils/uid";
import { getSearchText } from "./formatQueryText";

const adSlotId = "googleads-csa";

type Props = {
  className?: string;
  applyMinHeight?: boolean;
  onFilled?: () => void;
  onRequesting?: () => void;
  onNotFilled?: () => void;
};

// AdSense Customer Search Ads docs: https://developers.google.com/custom-search-ads/web/reference

export function CsaAd({
  className,
  applyMinHeight,
  onFilled,
  onRequesting,
  onNotFilled,
}: Props) {
  const intl = useIntl();
  const { origin, destination, searchResponse } = useSearch();
  const experimentConfig = useExperimentConfig();
  const adFillStrategy = useFeature("FillAds");

  const isProduction = import.meta.env.PROD;
  const isSegmentScreen = useIsValidSegment();

  // The ad container's width must be retrieved so we can tell Google the ad's width.
  // The width is being saved in a ref because we don't want to rerun effect (rerequest ad) if viewport changes.
  const adContainerWidth = useRef(300);
  const adContainerEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // "useEffect is run after the first render which means that adContainerEl will exist at this point,
    // but we're just checking to be safe.
    if (adContainerEl.current) {
      adContainerWidth.current = adContainerEl.current.offsetWidth;
    }
  }, []);

  const originCanonical = origin?.canonicalName;
  const destinationCanonical = destination?.canonicalName;

  const isFlightAd =
    searchResponse?.routes && searchResponse.routes.length > 0
      ? routeIsFlight(searchResponse.routes[0].canonicalName)
      : false;

  const languageCode = localeToLanguageCode(intl.locale);

  let searchText = getSearchText(
    intl,
    isFlightAd,
    originCanonical,
    destinationCanonical
  );

  const channelIds = getChannelIds(experimentConfig);

  useEffect(() => {
    // If we don't have search text, that means we need to wait for a search response before rendering an ad.
    // If adFillStrategy is "never" then we also don't want to render the ad.
    if (!searchText || adFillStrategy === "never") {
      return;
    }

    const options = {
      pubId: "pub-9138013975841188",
      query: searchText,
      hl: languageCode,
      adPage: 1,
      channel: channelIds,
      styleId: isSegmentScreen ? "8424616640" : "1821861746",
      adtest: isProduction ? "off" : "on",
    };

    const adBlock = {
      width: adContainerWidth,
      container: adSlotId,
      number: 6,
      adLoadedCallback: (containerName: string, adsLoaded: boolean) => {
        adsLoaded ? onFilled?.() : onNotFilled?.();
        logAdAnalytics(adsLoaded ? "Render" : "FailedToFill", options.query);
      },
    };

    if (window._googCsa) {
      window._googCsa.apply(null, ["ads", options, adBlock]);
      onRequesting?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    languageCode,
    isProduction,
    channelIds,
    isSegmentScreen,
    adFillStrategy,
  ]);

  return (
    <CsaAdContainer
      id={adSlotId}
      className={className}
      ref={adContainerEl}
      applyMinHeight={applyMinHeight}
    />
  );
}

// We set min-height to increase the distance between this ad and the next lazy-loaded ad.
// In doing so we ensure that the next ad only starts to load when users scroll closer to it
// which should improve ad viewability.
export const CsaAdContainer = styled.div<{ applyMinHeight?: boolean }>`
  ${(props) => props.applyMinHeight && "min-height: 450px;"}
`;

/** getChannelIds returns a concatenated string of all Adsense channel IDs that match the experiments
 * that a user is currently enrolled in. This helps us track the impact each experiment has on ads.
 */
function getChannelIds(experimentConfig?: ExperimentConfig) {
  let channelIds = [];

  const aqid = Cookies.get("aqid");

  channelIds.push(isAdWordsAcquisition(aqid) ? "5020671959" : "5113433154"); // AdWords

  switch (experimentConfig?.TripsAsCoreHoldback5050) {
    case "Baseline":
      channelIds.push("3938448220");
      break;
    case "FullExperience":
      channelIds.push("8841606335");
      break;
    default:
  }

  switch (experimentConfig?.DesktopInlineSerpAd90) {
    case "Baseline":
      channelIds.push("4246738559");
      break;
    case "InlineAd":
      channelIds.push("8406271571");
      break;
    default:
  }

  switch (experimentConfig?.TimeOfDayFilter) {
    case "Baseline":
      channelIds.push("6466960790");
      break;
    case "TimeOfDayFilter":
      channelIds.push("6138119315");
      break;
    default:
  }

  switch (experimentConfig?.LPINPDiagnosis) {
    case "Baseline":
      channelIds.push("4432662117");
      break;
    case "NoCMP":
      channelIds.push("6221119021");
      break;
    case "NoAds":
      channelIds.push("6903645303");
      break;
    case "NoDataSharing":
      channelIds.push("4908037354");
      break;
    default:
  }

  switch (experimentConfig?.LPHotelsBanner) {
    case "Baseline":
      channelIds.push("9344209456");
      break;
    case "LPHotelsPromoBannerA":
      channelIds.push("7262366822");
      break;
    case "LPHotelsPromoBannerB":
      channelIds.push("7433757216");
      break;
    default:
  }

  switch (experimentConfig?.MobileSerpAdExp90) {
    case "Baseline":
      channelIds.push("1432255370");
      break;
    case "MobileInlineSerpAd":
      channelIds.push("7007914056");
      break;
    default:
  }

  return channelIds.join("+");
}

export function prettyCanonicalName(canonicalName: string) {
  return canonicalName.replace(/-/g, " ");
}

export function routeIsFlight(canonical: string) {
  return canonical.toLowerCase().includes("fly");
}
const logAdAnalytics = sendAnalyticsNonInteractionEvent.bind(null, "Csa");
