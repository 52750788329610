import { ForwardedRef, ReactNode, forwardRef } from "react";
import { Icon } from "src/components/Icon/Icon";
import { border_radius } from "src/design-system/tokens/border";
import { ChevronRight } from "src/svg/ChevronRight";
import styled, { css } from "styled-components";
import { color, fontSize, fontWeight, spacing } from "../../../theme";

type IconAlign = "left" | "right";

type Props = {
  heading: string;
  tagline: string;
  icon: ReactNode;
  onClick: () => void;
  iconAlign?: IconAlign;
  fullWidth?: boolean;
  outline?: boolean;
};

export const SegmentPromoDriveVariant = forwardRef(
  (
    {
      heading,
      tagline,
      icon,
      onClick,
      iconAlign = "left",
      fullWidth = false,
      outline = false,
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <TimelineCta
        ref={ref}
        iconAlign={iconAlign}
        fullWidth={fullWidth}
        outline={outline}
        onClick={onClick}
      >
        <IconWrapper>
          <Icon size="xl">{icon}</Icon>
        </IconWrapper>
        <span>
          <Heading>{heading}</Heading>
          <Tagline>
            {tagline}
            <Chevron size="xs">
              <ChevronRight tint="pink" />
            </Chevron>
          </Tagline>
        </span>
      </TimelineCta>
    );
  }
);

const TimelineCta = styled.button<{
  iconAlign: IconAlign;
  fullWidth: boolean;
  outline: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${spacing.lg};
  padding: ${spacing.md};
  background-color: ${color.white};
  width: ${({ fullWidth }) => fullWidth && "100%"};
  cursor: pointer;

  // set as transparent to avoid component shifting when border is added on hover
  border: 2px solid transparent;

  &:hover {
    p {
      text-decoration: underline;
    }
  }

  ${({ iconAlign }) =>
    iconAlign === "left"
      ? `
    flex-direction: row;
    justify-content: left;
    text-align: left;
  `
      : `
    flex-direction: row-reverse;
    justify-content: right;
    text-align: right;
  `}

  ${({ outline }) =>
    outline &&
    `
      border: 1px solid ${color.n30};
      border-radius: ${border_radius.rounded_lg};
      padding: ${spacing.lg};
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: ${color.n20};
  border-radius: ${border_radius.rounded_md};
`;
const ctaText = css`
  font-size: ${fontSize.md};
  font-weight: ${fontWeight.medium};
`;

const Heading = styled.h3`
  ${ctaText}
  color: ${color.n300}
`;

const Tagline = styled.p`
  ${ctaText}
  display: inline-flex;
  align-items: center;
  color: ${color.pink};
`;

const Chevron = styled(Icon)`
  margin-left: ${spacing.sm};
`;
