import React, { useState } from "react";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { Typography } from "src/design-system/components/Typography/Typography";
import { Button } from "src/design-system/components/Button/Button";
import color from "src/design-system/tokens/color";
import Close from "src/svg/Close.svg?react";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { useNavigate } from "react-router";
import { bottomNavBarHeight } from "src/components/BottomNavbar/BottomNavbar";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { GeocodedPlace } from "src/PrefetchData";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useQueryClient } from "@tanstack/react-query";
import useUser from "src/utils/hooks/useUser";
import useSearch from "src/utils/hooks/useSearch";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { PlaceInputList } from "../TripEditorModal/PlaceInputList";
import messages from "./CreateTripScreen.messages";

export function CreateTripScreen() {
  const intl = useIntl();
  const { dispatch } = useTripPlannerContext();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const apiConfig = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const isUserLoggedIn = !!user?.id;
  const [tripDestinations, setTripDestinations] = useState<GeocodedPlace[]>([]);

  const { searchResponse } = useSearch();
  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "CreateTrip",
    undefined
  );

  function handleEditDestination(
    index: number,
    autocompletePlace: AutocompletePlace
  ) {
    autocompleteToGeocodedPlace(
      autocompletePlace,
      (geocodedPlace: GeocodedPlace) => {
        const newTripDestinations = tripDestinations.map((place, i) => {
          if (i === index) {
            return geocodedPlace;
          }
          return place;
        });
        setTripDestinations(newTripDestinations);
      },
      languageCode,
      queryClient,
      apiConfig
    );
  }

  function handleAddDestination(autocompletePlace: AutocompletePlace) {
    autocompleteToGeocodedPlace(
      autocompletePlace,
      (geocodedPlace: GeocodedPlace) => {
        setTripDestinations([...tripDestinations, geocodedPlace]);
      },
      languageCode,
      queryClient,
      apiConfig
    );
  }

  function handleCloseCreateTrip() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  function handleRemoveDestination(index: number) {
    const newTripDestinations = tripDestinations.filter((_, i) => i !== index);
    setTripDestinations(newTripDestinations);
  }

  function handleCreateTrip() {
    // If the user is logged in under multiple trips, we want to create a new trip.
    // If the user is not logged in, we want to replace the trip.
    const action = user?.id
      ? "CREATE_TRIP_FROM_SEARCH_TRIP"
      : "REPLACE_TRIP_FROM_SEARCH_TRIP";
    dispatch({
      type: action,
      places: tripDestinations,
    });
    handleCloseCreateTrip();
  }

  return (
    <Content>
      <Header>
        <LogoWrapper>
          <a href="/">
            <Rome2rioLogo
              title={intl.formatMessage(messages.homepage)}
              tint="cod"
              dotTint="pink"
            />
          </a>
        </LogoWrapper>
        {isUserLoggedIn && (
          <IconWrapper onClick={handleCloseCreateTrip}>
            <Close width={14} height={14} color={color.icon.icon} />
          </IconWrapper>
        )}
      </Header>
      <HeadingWrapper>
        <Typography variant="heading-md">
          {intl.formatMessage(messages.heading)}
        </Typography>
      </HeadingWrapper>
      <ContentWrapper>
        <PlaceInputList
          places={tripDestinations.map(
            (place) => place.longName ?? place.shortName
          )}
          handleRemoveDestination={handleRemoveDestination}
          handleAddDestination={handleAddDestination}
          handleEditDestination={handleEditDestination}
        />
      </ContentWrapper>
      <FixedBanner>
        <ButtonWrapper>
          <Button
            size="large"
            onPress={handleCreateTrip}
            isDisabled={tripDestinations.length < 2}
          >
            {intl.formatMessage(messages.cta)}
          </Button>
        </ButtonWrapper>
      </FixedBanner>
    </Content>
  );
}

const Header = styled.div`
  margin-bottom: ${spacing.xxl};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  svg {
    width: 120px;
  }
`;

const IconWrapper = styled.div`
  margin: 0 ${spacing.md};
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;

const ButtonWrapper = styled.div`
  margin: ${spacing.xl};
`;

const ContentWrapper = styled.div`
  padding-bottom: 77px; // height of fixed container
`;

const FixedBanner = styled.div`
  width: 100%;
  position: fixed;
  bottom: ${bottomNavBarHeight};
  left: 0;
  border-top: 1px solid ${color.border.border};

  button {
    width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));
`;
